
//PROYECTOS
$(document).ready(function () {
	jQuery.each(jQuery('.swiper--carousel.swiper--hero-home[data-module="swiper-hero"]'), function () {
		var slider = this;
		var $slider = $(slider);
		var slider_speed = $slider.data('speed') || 1000;
		var slider_parent = $slider.data('swiper-heritage');

		var swiper_slider = new Swiper(slider, {
			slidesPerView: 1,
			spaceBetween: 50,
			centeredSlides: true,
			speed: slider_speed,
			pagination: {
				el: '[data-swiper-parent="' + slider_parent + '"] .swiper-pagination',
				type: 'bullets',
				clickable: true,
			},
			navigation: {
				nextEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-next',
				prevEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-prev',
			}
			// ,
			// breakpoints: {
			// 	500: {
			// 		slidesPerView: 1.3,
			// 		spaceBetween: 8,
			// 		centeredSlides: true,
			// 	},
			// 	850: {
			// 		slidesPerView: 5,
			// 		allowTouchMove: true,
			// 		centeredSlides: false,
			// 	}
			// }
		});
	});

	jQuery.each(jQuery('.swiper--carousel.swiper--galeria-modal[data-module="swiper-galeria-modal"]'), function () {
		var slider = this;
		var $slider = $(slider);
		var slider_speed = $slider.data('speed') || 1000;
		var slider_parent = $slider.data('swiper-heritage');

		var swiper_slider = new Swiper(slider, {
			slidesPerView: 1,
			spaceBetween: 50, 
			centeredSlides: true,
			speed: slider_speed,
			pagination: {
				el: '[data-swiper-parent="' + slider_parent + '"] .swiper-pagination',
				type: 'bullets',
				clickable: true,
			},
			navigation: {
				nextEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-next',
				prevEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-prev',
			}
		});
	});

	jQuery.each(jQuery('.swiper--carousel.swiper--cifras[data-module="swiper-cifras"]'), function () {
		var slider = this;
		var $slider = $(slider);
		var slider_speed = $slider.data('speed') || 1000;
		var slider_parent = $slider.data('swiper-heritage');

		var swiper_slider = new Swiper(slider, {
			slidesPerView: 1.3,
			spaceBetween: 14,
			centeredSlides: false,
			speed: slider_speed,
			pagination: {
				el: '[data-swiper-parent="' + slider_parent + '"] .swiper-pagination',
				type: 'bullets',
				clickable: true,
			},
			navigation: {
				nextEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-next',
				prevEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-prev',
			},
			breakpoints: {
				500: {
					slidesPerView: 1.3,
					spaceBetween: 8,
					centeredSlides: true,
				},
				850: {
					slidesPerView: 3,
					allowTouchMove: true,
					centeredSlides: false,
				},
				1200: {
					slidesPerView: 4,
					allowTouchMove: true,
					centeredSlides: false,
				}
			}
		});
	});

	jQuery.each(jQuery('.swiper--carousel.swiper--timeline[data-module="swiper-timeline"]'), function () {
		var slider = this;
		var $slider = $(slider);
		var slider_speed = $slider.data('speed') || 1300;
		var slider_parent = $slider.data('swiper-heritage');
		
		var swiper_slider = new Swiper(slider, {
			slidesPerView: 1,
			spaceBetween: 0,
			speed: slider_speed,
			clickable: true, 
			centeredSlides: true,
			pagination: {
				el: '[data-swiper-parent="' + slider_parent + '"] .swiper-pagination',
				type: 'bullets',
				clickable: true,
			},
			navigation: {
				nextEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-next',
				prevEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-prev',
			},
			breakpoints: {
				1200: {
					slidesPerView: 3
				}
			}
		});

		// Agregar evento de clic a las cards para marcar como activa
		var cards = $slider.find('.box--timeline');
		cards.on('click', function () {
			// Remover la clase de activación de todas las cards
			cards.removeClass('active');
			// Agregar la clase de activación solo a la card seleccionada
			$(this).addClass('active');
			// Mover el carrusel para que la card activa esté centrada
			var cardIndex = cards.index(this);
			swiper_slider.slideTo(cardIndex);
		});
	});

	jQuery.each(jQuery('.swiper--carousel.swiper--timeline-texto[data-module="swiper-timeline-texto"]'), function () {
		var slider = this;
		var $slider = $(slider);
		var slider_speed = $slider.data('speed') || 1000;
		var slider_parent = $slider.data('swiper-heritage');

		var swiper_slider = new Swiper(slider, {
			slidesPerView: 1,
			spaceBetween: 0,
			speed: slider_speed,
			clickable: true, 
			centeredSlides: true,
			pagination: {
				el: '[data-swiper-parent="' + slider_parent + '"] .swiper-pagination',
				type: 'bullets',
				clickable: true,
			},
			navigation: {
				nextEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-next',
				prevEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-prev',
			},
			breakpoints: {
				1200: {
					slidesPerView: 3
				}
			}
		});

		// Agregar evento de clic a las cards para marcar como activa
		var cards = $slider.find('.box--timeline-texto');
		cards.on('click', function () {
			// Remover la clase de activación de todas las cards
			cards.removeClass('active');
			// Agregar la clase de activación solo a la card seleccionada
			$(this).addClass('active');
			// Mover el carrusel para que la card activa esté centrada
			var cardIndex = cards.index(this);
			swiper_slider.slideTo(cardIndex);
		});
	});

	jQuery.each(jQuery('.swiper--carousel.swiper--calendario[data-module="swiper-calendario"]'), function () {
		var slider = this;
		var $slider = $(slider);
		var slider_speed = $slider.data('speed') || 1000;
		var slider_parent = $slider.data('swiper-heritage');

		var swiper_slider = new Swiper(slider, {
			slidesPerView: 1,
			spaceBetween: 14,
			centeredSlides: true,
			speed: slider_speed,
			pagination: {
				el: '[data-swiper-parent="' + slider_parent + '"] .swiper-pagination',
				type: 'bullets',
				clickable: true,
			},
			navigation: {
				nextEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-next',
				prevEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-prev',
			},
			breakpoints: {
				500: {
					slidesPerView: 1.3,
					spaceBetween: 8,
					centeredSlides: true,
				},
				850: {
					slidesPerView: 3,
					allowTouchMove: true,
					centeredSlides: false,
				},
				1200: {
					slidesPerView: 4,
					allowTouchMove: true,
					centeredSlides: false,
				}
			}
		});
	});

	jQuery.each(jQuery('.swiper--carousel.swiper--etapas[data-module="swiper-etapas"]'), function () {
		var slider = this;
		var $slider = $(slider);
		var slider_speed = $slider.data('speed') || 1000;
		var slider_parent = $slider.data('swiper-heritage');

		var swiper_slider = new Swiper(slider, {
			slidesPerView: 1,
			spaceBetween: 14,
			centeredSlides: true,
			speed: slider_speed,
			pagination: {
				el: '[data-swiper-parent="' + slider_parent + '"] .swiper-pagination',
				type: 'bullets',
				clickable: true,
				renderBullet: function (index, className) {
					var etapaIndex = index + 1;
					var etapaName = "Etapa " + etapaIndex;
					return '<span class="' + className + '">' + etapaName + '</span>';
				}
			},
			navigation: {
				nextEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-next',
				prevEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-prev',
			},
			breakpoints: {
				500: {
					slidesPerView: 1.3,
					spaceBetween: 8,
					centeredSlides: true,
				},
				850: {
					slidesPerView: 2.3,
					allowTouchMove: true, 
					centeredSlides: false,
				},
				1200: {
					slidesPerView: 3,
					allowTouchMove: true,
				}
			}
		});
	});

	jQuery.each(jQuery('.swiper--carousel.swiper--etapas[data-module="swiper-etapas-doble"]'), function () {
		var slider = this;
		var $slider = $(slider);
		var slider_speed = $slider.data('speed') || 1000;
		var slider_parent = $slider.data('swiper-heritage');

		var swiper_slider = new Swiper(slider, {
			slidesPerView: 1,
			spaceBetween: 14,
			centeredSlides: true,
			speed: slider_speed,
			pagination: {
				el: '[data-swiper-parent="' + slider_parent + '"] .swiper-pagination',
				type: 'bullets',
				clickable: true,
				renderBullet: function (index, className) {
					var etapaIndex = index + 1;
					var etapaName = "Etapa " + etapaIndex;
					return '<span class="' + className + '">' + etapaName + '</span>';
				}
			},
			navigation: {
				nextEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-next',
				prevEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-prev',
			},
			breakpoints: {
				500: {
					slidesPerView: 1,
					spaceBetween: 8,
				},
				850: {
					slidesPerView: 1.5,
					allowTouchMove: true, 
				},
				1200: {
					slidesPerView: 1.8,
					allowTouchMove: true,
				}
			}
		});
	});
	jQuery.each(jQuery('.swiper--carousel.swiper--calendario[data-module="swiper-calendario-tres"]'), function () {
		var slider = this;
		var $slider = $(slider);
		var slider_speed = $slider.data('speed') || 1000;
		var slider_parent = $slider.data('swiper-heritage');

		var swiper_slider = new Swiper(slider, {
			slidesPerView: 1,
			spaceBetween: 14,
			centeredSlides: true,
			speed: slider_speed,
			pagination: {
				el: '[data-swiper-parent="' + slider_parent + '"] .swiper-pagination',
				type: 'bullets',
				clickable: true,
			},
			navigation: {
				nextEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-next',
				prevEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-prev',
			},
			breakpoints: {
				500: {
					slidesPerView: 1.3,
					spaceBetween: 8,
					centeredSlides: true,
				},
				850: {
					slidesPerView: 2.3,
					allowTouchMove: true,
					centeredSlides: false,
				},
				1200: {
					slidesPerView: 3,
					allowTouchMove: true,
					centeredSlides: false,
				}
			}
		});
	});

	jQuery.each(jQuery('.swiper--carousel.swiper--acceso[data-module="swiper-acceso"]'), function () {
		var slider = this;
		var $slider = $(slider);
		var slider_speed = $slider.data('speed') || 1000;
		var slider_parent = $slider.data('swiper-heritage');

		var swiper_slider = new Swiper(slider, {
			slidesPerView: 1.3,
			spaceBetween: 14,
			centeredSlides: false,
			speed: slider_speed,
			pagination: {
				el: '[data-swiper-parent="' + slider_parent + '"] .swiper-pagination',
				type: 'bullets',
				clickable: true,
			},
			navigation: {
				nextEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-next',
				prevEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-prev',
			},
			breakpoints: {
				500: {
					slidesPerView: 1.3,
					spaceBetween: 8,
					centeredSlides: false,
					loop: true,
				},
				1200: {
					slidesPerView: 3,
					allowTouchMove: true,
					centeredSlides: false,
					loop: false,
				}
			}
		});
	});

	jQuery.each(jQuery('.swiper--carousel.swiper--citas-doble[data-module="swiper-citas-doble"]'), function () {
		var slider = this;
		var $slider = $(slider);
		var slider_speed = $slider.data('speed') || 1000;
		var slider_parent = $slider.data('swiper-heritage');

		var swiper_slider = new Swiper(slider, {
			slidesPerView: 1,
			spaceBetween: 14,
			centeredSlides: false,
			speed: slider_speed,
			pagination: {
				el: '[data-swiper-parent="' + slider_parent + '"] .swiper-pagination',
				type: 'bullets',
				clickable: true,
			},
			navigation: {
				nextEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-next',
				prevEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-prev',
			},
			breakpoints: {
				500: {
					slidesPerView: 1.3,
					spaceBetween: 8,
					centeredSlides: false,
					loop: true,
				},
				1200: {
					slidesPerView: 2,
					allowTouchMove: true,
					centeredSlides: false,
					loop: false,
				}
			}
		});
	});

	jQuery.each(jQuery('.swiper--carousel.swiper--cita-simple[data-module="swiper-cita-simple"]'), function () {
		var slider = this; 
		var $slider = $(slider);
		var slider_speed = $slider.data('speed') || 1000;
		var slider_parent = $slider.data('swiper-heritage');

		var swiper_slider = new Swiper(slider, {
			slidesPerView: 1,
			spaceBetween: 20,
			centeredSlides: false,
			speed: slider_speed,
			pagination: {
				el: '[data-swiper-parent="' + slider_parent + '"] .swiper-pagination',
				type: 'bullets',
				clickable: true,
			},
			navigation: {
				nextEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-next',
				prevEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-prev',
			}
		});
	});

	jQuery.each(jQuery('.swiper--carousel.swiper--acceso-externo[data-module="swiper-acceso-externo"]'), function () {
		var slider = this;
		var $slider = $(slider);
		var slider_speed = $slider.data('speed') || 1000;
		var slider_parent = $slider.data('swiper-heritage');

		var swiper_slider = new Swiper(slider, {
			slidesPerView: 1,
			spaceBetween: 20,
			centeredSlides: true,
			speed: slider_speed,
			pagination: {
				el: '[data-swiper-parent="' + slider_parent + '"] .swiper-pagination',
				type: 'bullets',
				clickable: true,
			},
			navigation: {
				nextEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-next',
				prevEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-prev',
			}
		});
	});

	jQuery.each(jQuery('.swiper--carousel.swiper--noticias[data-module="swiper-noticias"]'), function () {
		var slider = this;
		var $slider = $(slider);
		var slider_speed = $slider.data('speed') || 1000;
		var slider_parent = $slider.data('swiper-heritage');

		var swiper_slider = new Swiper(slider, {
			slidesPerView: 1,
			spaceBetween: 14,
			centeredSlides: true,
			speed: slider_speed,
			pagination: {
				el: '[data-swiper-parent="' + slider_parent + '"] .swiper-pagination',
				type: 'bullets',
				clickable: true,
			},
			navigation: {
				nextEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-next',
				prevEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-prev',
			},
			breakpoints: {
				500: {
					slidesPerView: 1.3,
					spaceBetween: 8,
					centeredSlides: true,
				},
				850: {
					slidesPerView: 3,
					allowTouchMove: true,
					centeredSlides: false,
				},
				1200: {
					slidesPerView: 4,
					allowTouchMove: true,
					centeredSlides: false,
				}
			}
		});
	});
});


